import React from "react"

import Layout from "../components/layout"
import {graphql, Link} from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";

const IndexPage = ({data: {allDatoCmsCitygroup: continents, datoCmsCitiespage: page}}) => (
  <Layout>
    <SEO title="Continents" seo={page.seoMetaTags}/>
    <div className='flex flex-col items-center mb-8'>
      <h1 className='font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider'>{page.title}</h1>
      <span className="font-sans font-light text-gray-800 text-center mx-2 sm:mx-6 lg:mx-32 mb-4 "
            dangerouslySetInnerHTML={{__html: page.description}}/>
      <nav className='pb-4'>
        <Link className='m-2 font-title text-purple-800 no-underline text-2xl' to='/cities/'>City</Link>
        <Link className='m-2 font-title text-gray-400 no-underline text-2xl text-grey'
              to='/continents/'>Continent</Link>
      </nav>
      <div className='flex flex-wrap justify-center w-full'>
        {continents.edges.map(({node: city}) =>
          <Link className='relative flex-grow m-1' to={'/continents/' + city.slug + '/'} key={city.slug}>
            <Img className='darken' style={{minWidth: 200}} fluid={city.image.fluid}/>
            <span
              className='z-20 absolute uppercase text-white city-title font-title font-light text-xl'>{city.title}</span>
          </Link>
        )}
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
    query Continents {
        allDatoCmsCitygroup(sort:{ fields: [title] }) {
            edges {
                node {
                    id
                    title
                    slug
                    image {
                        fluid(maxWidth: 300, imgixParams: { fit: "crop", w:"300", h:"300" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
        datoCmsCitiespage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            description
        }
    }
`
